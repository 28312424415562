import React, { useState, useMemo } from "react"
import { useAppDispatch } from "../../redux/configureStore"
import * as Yup from "yup"
import { Alert, FormControl, FormHelperText, Typography } from "@mui/material"
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"
import CreditIcons from "../../images/icons/creditIcons.svg"
import { Button } from "../Button"
import {
  CheckoutBox,
  BillingDetailsLabel,
  BlueBox,
  BlueLabel,
  BorderedBox,
  ButtonBox,
  CardInformationLabel,
  CheckoutHeader,
  FormBox,
  FormFlexBox,
  FormFlexBoxItem,
  MUIField,
} from "./styles"
import {
  PaymentDetails,
  createNewPayment,
  notifySuccessPayment,
} from "../../redux/payment"
import { Product } from "../../redux/product"
import { Member } from "../../redux/account"
import countryList from "react-select-country-list"
import SelectField from "../SelectField"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { updatePrice } from "../../redux/reprice"
import coupon from "../../redux/coupon"
import { removeCookie, setCookie } from "../../utils/cookies"
import { getSetupIntent, updatePaymentMethod } from "../../redux/creditcard"
import { getProration } from "../../redux/network/prorate"
import {
  createSubscription,
  attachSubscription,
} from "../../redux/network/payment"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import states from "states-us"
import { useAuth } from "../../providers/AuthProvider"
import { useOrgDiscount } from "../../hooks/useOrgDiscount"
import { CeoNoteDialog } from "../CeoNoteDialog/ceoNoteDialog"
import { Field, Form, Formik, FormikHelpers } from "formik"

import "./stripeStyles.css"

const zipcodes = require("zipcodes")

interface Props {
  products?: Product[]
  member?: Member
  isAREProduct?: boolean
  widget?: boolean
  setPage?: (num: number) => void
  updateCardFormVisible?: (val: boolean) => void
  updateBillingForm?: boolean
  getPaymentInfo?: () => void
  couponCode?: string
  setCouponCode?: (value: string) => void
}

const StripeTextField = React.memo(
  ({ id, component: Component, options, error, ...props }: any) => {
    const [focused, setFocused] = useState(false)

    return (
      <FormControl fullWidth variant="outlined" className="formControl">
        <div
          className={`stripeElementContainer ${focused ? "focused" : ""} ${
            error ? "error" : ""
          }`}
        >
          <Component
            id={id}
            options={options}
            className="stripeElement"
            {...props}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        </div>
        {error && (
          <FormHelperText className="stripeErrorText">{error}</FormHelperText>
        )}
      </FormControl>
    )
  }
)

const View = ({
  products,
  member,
  isAREProduct,
  widget,
  setPage,
  updateCardFormVisible,
  updateBillingForm,
  getPaymentInfo,
  couponCode,
  setCouponCode,
}: Props) => {
  useOrgDiscount()
  const auth = useAuth()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const countryOptions = useMemo(() => countryList().getData(), [])
  const stateOptions = states.map(({ abbreviation, name }) => {
    return { value: abbreviation, label: name }
  })

  // for confirmation page of ARE products
  const [open, setOpen] = useState(false)

  const [responseCartId, setResponseCartId] = useState("")
  const [responseClientSecret, setResponseClientSecret] = useState("")
  const [responseSubId, setResponseSubId] = useState("")
  const [taxId, setTaxId] = useState("")
  const [backDropVisible, updateBackDropVisible] = useState(false)

  const items = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )

  const subscriptions = useSelector(
    (state: AppState) => state.subscription.activeSubscription
  )

  const billingInfoStoreState = useSelector(
    (state: AppState) => state.billingInfo.paymentMethods
  )

  const cartProrateDetails = useSelector(
    (state: AppState) => state.prorate.cartProrateDetails
  )

  const cartDetails = useSelector(
    (state: AppState) => state.reprice.cartDetails
  )

  const [cardErrors, setCardErrors] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  })

  const [stripeComplete, setStripeComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })
  const [stripeError, setStripeError] = useState("")

  const initialValues: PaymentDetails = {
    cardName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    postalCode: "",
    phoneNumber: "",
    phoneExtension: "",
    saveCreditCard: false,
  }

  const handlePaymentSubmit = async (
    values: PaymentDetails,
    actions: FormikHelpers<PaymentDetails>
  ) => {
    if (!products) return

    window.dataLayer.push({ event: "payNow" })

    actions.setSubmitting(true)

    if (!stripe || !elements) {
      actions.setSubmitting(false)
      return
    }

    updateBackDropVisible(true)

    try {
      const paymentDetails = {
        member: {
          memberId: member?.memberId,
        },
        ...values,
      }

      let clientSecret = responseClientSecret
      let cartId = responseCartId
      let subId = responseSubId

      if (!responseCartId && !responseClientSecret && !responseSubId) {
        const { tax_rate_id }: any = cartDetails

        const { payload } = await dispatch(
          createNewPayment({
            auth,
            paymentDetails,
            products,
            coupon: couponCode,
            taxId: tax_rate_id,
          })
        )

        clientSecret = payload.items[0].client_secret
        subId = payload.items[0].subscription_id

        setResponseCartId(cartId)
        setResponseClientSecret(clientSecret)
        setResponseSubId(subId)
      }

      if (
        createNewPayment.fulfilled.type === "/createNewPayment/fulfilled" ||
        (responseCartId && responseClientSecret && responseSubId)
      ) {
        const cardNumber = elements.getElement("cardNumber")
        if (cardNumber) {
          const options = {
            name: values.cardName,
            address: {
              postal_code: values.postalCode,
              line1: values.address1,
              line2: values.address2,
              city: values.city,
              state: values.state,
              country: values.country,
            },
            phone: values.phoneNumber,
          }

          stripe
            .confirmCardPayment(clientSecret, {
              payment_method: {
                card: cardNumber,
                billing_details: options,
              },
            })
            .then(async (result) => {
              if (result.error) {
                setStripeError(result.error.message ?? "")
                updateBackDropVisible(false)
                setResponseCartId("")
                setResponseClientSecret("")
                setResponseSubId("")
                actions.setSubmitting(false)
              } else {
                if (member?.memberId) {
                  const pmId = result.paymentIntent.payment_method

                  const successResponse = await dispatch(
                    notifySuccessPayment({
                      auth,
                      cartId,
                      subId,
                      pmId,
                    })
                  )

                  updateBackDropVisible(false)
                  if (isAREProduct) setOpen(true)

                  removeCookie("BKS_are5_Checkout_Product")
                  removeCookie("BKS_software_Checkout_Product")
                  if (setCouponCode) setCouponCode("")

                  const items = products.map((product) => ({
                    item_name: `${product?.name}`,
                    item_id: `${product?.id}`,
                    price: (product?.amount / 100).toFixed(2),
                    item_category: ``,
                    item_category2: ``,
                    quantity: "1",
                  }))

                  let productDetails

                  if (cartProrateDetails) {
                    productDetails = {
                      value: (cartProrateDetails.sub_total / 100).toFixed(2),
                      tax: (cartProrateDetails.tax / 100).toFixed(2),
                      credit: (cartProrateDetails.credit / 100).toFixed(2),
                    }
                  } else if (cartDetails) {
                    productDetails = {
                      value: (cartDetails?.sub_total / 100).toFixed(2),
                      tax: (cartDetails.tax / 100).toFixed(2),
                      credit: "0",
                    }
                  }

                  window.dataLayer.push({
                    event: "checkoutSuccess",
                    price: `Total price: ${productDetails?.value}`,
                    ecommerce: {
                      value: `${productDetails?.value}`,
                      credit: `${productDetails?.credit}`,
                      tax: `${productDetails?.tax}`,
                      transaction_id: `${successResponse.payload.invoice_id}`,
                      coupon,
                      items,
                    },
                  })
                }
              }
            })
        }
      }
    } catch (error) {
      updateBackDropVisible(false)
      actions.setSubmitting(false)
      window.dataLayer.push({ event: "checkoutSubmissionUnsuccessful" })
    }
  }

  const handleBillingUpdate = async (
    values: PaymentDetails,
    actions: FormikHelpers<PaymentDetails>
  ) => {
    actions.setSubmitting(true)

    if (!stripe || !elements) {
      actions.setSubmitting(false)
      return
    }
    // restart cart if user is not logged in
    if (!auth.isAuthenticated) {
      window.location.reload()
      return
    }

    updateBackDropVisible(true)

    try {
      // for users that are updating their credit card
      if (
        (billingInfoStoreState &&
          billingInfoStoreState?.payment_methods &&
          billingInfoStoreState.payment_methods.length) ||
        // widgets can only be used to update credit cards
        widget
      ) {
        updateCardSetup(values, actions)
        if (setPage) setPage(1)
      } else {
        handleFirstCardSetup(values, actions)
      }
    } catch (error) {
      updateBackDropVisible(false)
    }
  }

  const updateCardSetup = async (
    values: PaymentDetails,
    actions: FormikHelpers<PaymentDetails>
  ) => {
    if (!stripe || !elements) {
      actions.setSubmitting(false)
      return
    }

    const { payload, type } = await dispatch(getSetupIntent({ auth }))
    const { client_secret } = payload

    if (getSetupIntent.fulfilled.type !== type) return

    const cardNumber = elements.getElement("cardNumber")

    if (cardNumber) {
      const options = {
        name: values.cardName,
        address: {
          postal_code: values.postalCode,
          line1: values.address1,
          line2: values.address2,
          city: values.city,
          state: values.state,
          country: values.country,
        },
        phone: values.phoneNumber,
      }

      const result = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: cardNumber,
          billing_details: options,
        },
      })

      if (result.error) {
        actions.setSubmitting(false)
        return
      }

      const pmId = result?.setupIntent?.payment_method

      if (pmId) {
        attachCard(pmId, actions)
      }
    }
  }

  // Make payment for new users that created an account without completing checkout
  const handleFirstCardSetup = async (
    values: PaymentDetails,
    actions: FormikHelpers<PaymentDetails>
  ) => {
    if (!stripe || !elements) {
      actions.setSubmitting(false)
      return
    }

    if (!products) return

    const paymentDetails = {
      member: {
        memberId: member?.memberId,
      },
      ...values,
    }

    let clientSecret = responseClientSecret
    let cartId = responseCartId
    let subId = responseSubId

    if (!responseCartId && !responseClientSecret && !responseSubId) {
      const { payload, type } = await dispatch(
        createSubscription({
          auth,
          paymentDetails,
          products,
          coupon: couponCode,
          taxId,
        })
      )

      clientSecret = payload.items[0].client_secret
      subId = payload.items[0].subscription_id
      if (createSubscription.fulfilled.type === type) {
        const cardNumber = elements.getElement("cardNumber")
        if (cardNumber) {
          const options = {
            name: values.cardName,
            address: {
              postal_code: values.postalCode,
              line1: values.address1,
              line2: values.address2,
              city: values.city,
              state: values.state,
              country: values.country,
            },
            phone: values.phoneNumber,
          }

          const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardNumber,
              billing_details: options,
            },
          })

          if (result.error) {
            updateBackDropVisible(false)
            actions.setSubmitting(false)

            setResponseCartId("")
            setResponseClientSecret("")
            setResponseSubId("")
            return
          }

          const pmId = result?.paymentIntent?.payment_method
          if (pmId) {
            const res = await dispatch(
              attachSubscription({
                auth,
                cartId,
                subId,
                pmId,
              })
            )

            if (res.type === "/attachSubscription/rejected") {
              // TODO: Display error message
              updateBackDropVisible(false)
              return
            }

            // Display payment confirmation message
            setOpen(true)

            const items = products.map((product) => ({
              item_name: `${product?.name}`,
              item_id: `${product?.id}`,
              price: (product?.amount / 100).toFixed(2),
              item_category: ``,
              item_category2: ``,
              quantity: "1",
            }))

            let productDetails

            if (cartProrateDetails) {
              productDetails = {
                value: (cartProrateDetails.sub_total / 100).toFixed(2),
                tax: (cartProrateDetails.tax / 100).toFixed(2),
                credit: (cartProrateDetails.credit / 100).toFixed(2),
              }
            } else if (cartDetails) {
              productDetails = {
                value: (cartDetails?.sub_total / 100).toFixed(2),
                tax: (cartDetails.tax / 100).toFixed(2),
                credit: "0",
              }
            }

            window.dataLayer.push({
              event: "checkoutSuccess",
              price: `Total price: ${productDetails?.value}`,
              ecommerce: {
                value: `${productDetails?.value}`,
                credit: `${productDetails?.credit}`,
                tax: `${productDetails?.tax}`,
                transaction_id: `${res.payload.invoice_id}`,
                coupon,
                items,
              },
            })
          }
        }
      }
    }
  }

  const attachCard = async (
    pmId: any,
    actions: FormikHelpers<PaymentDetails>
  ) => {
    const res = await dispatch(updatePaymentMethod({ pmId, auth }))

    // Return to payment info on new checkout cart site
    if (
      res.payload.status &&
      updateCardFormVisible &&
      getPaymentInfo &&
      !widget
    ) {
      getPaymentInfo()
      updateCardFormVisible(false)
      actions.setSubmitting(false)
    }
    // Return to payment info section on widget within EE
    else if (
      res.payload.status &&
      updateCardFormVisible &&
      getPaymentInfo &&
      widget
    ) {
      getPaymentInfo()
      updateCardFormVisible(false)
      setCookie("scrollTop", "true")
      actions.setSubmitting(false)
      window.location.reload()
    }

    updateBackDropVisible(false)
    window.location.reload()
  }

  const digitsOnly = (value: any) => /^\d+$/.test(value)

  const validationSchema = Yup.object().shape({
    cardName: Yup.string()
      .required("Required")
      .max(25, "Name on card must be less than 25 characters"),
    address1: Yup.string()
      .required("Required")
      .max(80, "Address line 1 must be less than 80 characters"),
    address2: Yup.string().max(
      80,
      "Address line 2 must be less than 80 characters"
    ),
    city: Yup.string()
      .required("Required")
      .max(50, "City must be less than 50 characters"),
    state: Yup.string()
      .required("Required")
      .max(50, "State must be less than 50 characters"),
    country: Yup.string().required("Required"),
    postalCode: Yup.string()
      .max(10)
      .required("Required")
      .test(
        "is-valid-zipcode",
        "The postal code is not valid.",
        function (value) {
          const { country, state } = this.parent
          if (country === "US") {
            const postalCodeData = zipcodes.lookup(value)
            if (!postalCodeData) {
              return false
            }

            if (state && postalCodeData.state !== state.toUpperCase()) {
              return this.createError({
                message: `The postal code belongs to the state ${postalCodeData.state}, but the provided state is ${state}.`,
              })
            }
          }
          return true
        }
      ),
    phoneNumber: Yup.string()
      .test("Digits only", "The field should have digits only", digitsOnly)
      .length(10, "Phone number must be 10 characters")
      .required("Required"),
    phoneExtension: Yup.string().max(
      5,
      "Phone extension must be less than 5 characters"
    ),
  })

  const checkTaxes = async (formValues: PaymentDetails) => {
    const { address1, city, state, postalCode, country } = formValues

    if (address1 && city && state && postalCode && country) {
      const address = {
        address1,
        address2: "",
        city,
        state,
        postal_code: postalCode,
        country,
      }

      const cartItems: any = []
      if (items) {
        items.forEach((product: any) => {
          const item = {
            id: product.id,
            price: (product.amount / 100).toFixed(2),
            qty: "1",
            name: product.name,
          }

          cartItems.push(item)
        })
      } else if (subscriptions && subscriptions.length) {
        const subscription = subscriptions.filter((subscription) =>
          subscription?.name.includes("ARE 5.0")
        )

        subscription.forEach((product: any) => {
          const item = {
            id: product.price_id,
            price: (product.base_price / 100).toFixed(2),
            qty: "1",
            name: product.name,
          }
          cartItems.push(item)
        })
      } else if (widget) {
        const qaENV =
          window.location.href.includes("-qa") ||
          window.location.href.includes("local")

        const placeholderItem = {
          id: qaENV
            ? "price_1OCxtz267oeCP8Yt7eGMltdR"
            : "price_1ODTxI267oeCP8YtywLcIRxd",
          price: "599.00",
          qty: "1",
          name: "ARE 5.0 Expert 1-Month Subscription Firm",
        }
        cartItems.push(placeholderItem)
      }

      try {
        const fields = {
          items: cartItems,
          coupon: couponCode,
          address,
        }

        if (widget) {
          const { type, payload }: any = await dispatch(updatePrice(fields))
          if (type !== "/reprice/fulfilled") {
            return
          }

          setTaxId(payload.tax_rate_id)
        } else if (
          subscriptions &&
          subscriptions.length > 0 &&
          auth.isAuthenticated
        ) {
          subscriptions.forEach((subscription: any) => {
            if (subscription.name.indexOf("ARE 5.0") >= 0) {
              fields.items[0]["target_subscription_id"] = subscription.id
            }
          })

          const { type, payload } = await dispatch(
            getProration({ auth, ...fields })
          )

          if (type !== "/prorate/fulfilled") {
            return
          }

          setTaxId(payload.tax_rate_id)
        } else {
          const { type, payload }: any = await dispatch(updatePrice(fields))

          if (type !== "/reprice/fulfilled") {
            return
          }

          setTaxId(payload.tax_rate_id)
        }
      } catch (error) {
        return
      }
    }
  }

  const handleClick = () => {
    window.dataLayer.push({ event: "termsAndConditions" })

    window.open(
      process.env.REACT_APP_TERMS_AND_CONDITIONS_URL ||
        "https://go.blackspectacles.com/terms-and-conditions",
      "_blank"
    )
  }
  const handleClickPolicy = () => {
    window.dataLayer.push({ event: "privacyPolicy" })

    window.open("https://www.blackspectacles.com/privacy-policy","_blank")
  }

  const handleCancel = () => {
    if (updateCardFormVisible) updateCardFormVisible(false)
  }

  const InputProps = {
    style: {
      fontSize: "16px",
      height: "48px",
      marginTop: "10px",
      backgroundColor: "#fafafa",
    },
  }

  const isStripeValid =
    cardErrors.cardNumber === "" &&
    stripeComplete.cardNumber &&
    cardErrors.cardExpiry === "" &&
    stripeComplete.cardExpiry &&
    cardErrors.cardCvc === "" &&
    stripeComplete.cardCvc

  return (
    <>
      <CheckoutBox>
        <CheckoutHeader>
          <Typography variant="h3">
            {updateBillingForm
              ? "Step 3: Update Billing Info"
              : "Step 2: Secure Checkout"}
          </Typography>
        </CheckoutHeader>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            updateBillingForm
              ? handleBillingUpdate(values, actions)
              : handlePaymentSubmit(values, actions)
          }}
        >
          {({ values, isSubmitting, touched, errors, isValid }) => (
            <Form id="billingForm" method="POST">
              {stripeError && (
                <Alert severity="error" style={{ marginBottom: "20px" }}>
                  {stripeError}
                </Alert>
              )}
              <FormBox>
                <CardInformationLabel variant="h4">
                  1. Card information
                  <img src={CreditIcons} alt={"credit card icons"} />
                </CardInformationLabel>
                <BorderedBox boxPosition={1}>
                  <FormFlexBox>
                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        Name on card <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <Field
                        name="cardName"
                        as={MUIField}
                        fullWidth
                        margin="normal"
                        helperText={
                          touched.cardName && errors.cardName
                            ? errors.cardName
                            : ""
                        }
                        error={touched.cardName && Boolean(errors.cardName)}
                        variant="filled"
                        placeholder="John Smith"
                        InputProps={InputProps}
                      />
                    </FormFlexBoxItem>
                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        Credit or debit card number{" "}
                        <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <StripeTextField
                        id="cardNumber"
                        component={CardNumberElement}
                        error={cardErrors.cardNumber}
                        onChange={(event: any) => {
                          if (event.error) {
                            setCardErrors((prevErrors) => ({
                              ...prevErrors,
                              cardNumber: event.error?.message || "",
                            }))
                          } else {
                            setCardErrors((prevErrors) => ({
                              ...prevErrors,
                              cardNumber: "",
                            }))
                          }
                          setStripeComplete((prevComplete) => ({
                            ...prevComplete,
                            cardNumber: event.complete,
                          }))
                        }}
                      />
                    </FormFlexBoxItem>
                  </FormFlexBox>
                  <FormFlexBox marginTop={2}>
                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        Expiration date{" "}
                        <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <StripeTextField
                        id="cardExpiry"
                        component={CardExpiryElement}
                        error={cardErrors.cardExpiry}
                        onChange={(event: any) => {
                          if (event.error) {
                            setCardErrors((prevErrors) => ({
                              ...prevErrors,
                              cardExpiry: event.error?.message || "",
                            }))
                          } else {
                            setCardErrors((prevErrors) => ({
                              ...prevErrors,
                              cardExpiry: "",
                            }))
                          }
                          setStripeComplete((prevComplete) => ({
                            ...prevComplete,
                            cardExpiry: event.complete,
                          }))
                        }}
                      />
                    </FormFlexBoxItem>
                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        Security code{" "}
                        <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <StripeTextField
                        id="cardCvc"
                        component={CardCvcElement}
                        error={cardErrors.cardCvc}
                        onChange={(event: any) => {
                          if (event.error) {
                            setCardErrors((prevErrors) => ({
                              ...prevErrors,
                              cardCvc: event.error?.message || "",
                            }))
                          } else {
                            setCardErrors((prevErrors) => ({
                              ...prevErrors,
                              cardCvc: "",
                            }))
                          }
                          setStripeComplete((prevComplete) => ({
                            ...prevComplete,
                            cardCvc: event.complete,
                          }))
                        }}
                      />
                    </FormFlexBoxItem>
                  </FormFlexBox>
                </BorderedBox>
                <BillingDetailsLabel variant="h4">
                  2. Billing details
                </BillingDetailsLabel>
                <BorderedBox boxPosition={2}>
                  <FormFlexBox>
                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        Address line 1{" "}
                        <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <Field
                        name="address1"
                        id="address1"
                        as={MUIField}
                        fullWidth
                        margin="normal"
                        helperText={
                          touched.address1 && errors.address1
                            ? errors.address1
                            : ""
                        }
                        error={touched.address1 && Boolean(errors.address1)}
                        variant="filled"
                        placeholder="Street"
                        onBlur={() => checkTaxes(values)}
                        InputProps={InputProps}
                      />
                    </FormFlexBoxItem>

                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        Address line 2{" "}
                        <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <Field
                        name="address2"
                        id="address2"
                        as={MUIField}
                        fullWidth
                        margin="normal"
                        helperText={
                          touched.address2 && errors.address2
                            ? errors.address2
                            : ""
                        }
                        error={touched.address2 && Boolean(errors.address2)}
                        variant="filled"
                        placeholder="Apt, suite, etc. (optional)"
                        onBlur={() => checkTaxes(values)}
                        InputProps={InputProps}
                      />
                    </FormFlexBoxItem>
                  </FormFlexBox>
                  <FormFlexBox marginTop={2}>
                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        City <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <Field
                        name="city"
                        id="city"
                        as={MUIField}
                        fullWidth
                        margin="normal"
                        helperText={
                          touched.city && errors.city ? errors.city : ""
                        }
                        error={touched.city && Boolean(errors.city)}
                        variant="filled"
                        placeholder="Chicago"
                        onBlur={() => checkTaxes(values)}
                        InputProps={InputProps}
                      />
                    </FormFlexBoxItem>

                    <FormFlexBoxItem>
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        State <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      {values.country !== "US" ? (
                        <Field
                          name="state"
                          id="state"
                          as={MUIField}
                          fullWidth
                          margin="normal"
                          helperText={
                            touched.state && errors.state ? errors.state : ""
                          }
                          error={touched.state && Boolean(errors.state)}
                          variant="filled"
                          onBlur={() => checkTaxes(values)}
                          InputProps={InputProps}
                        />
                      ) : (
                        <SelectField
                          name={"state"}
                          id="state"
                          onFieldChange={(e: any) =>
                            (values.state = e.target.value)
                          }
                          helperText={
                            touched.state && errors.state ? errors.state : ""
                          }
                          error={touched.state && Boolean(errors.state)}
                          options={stateOptions}
                          variant={"filled"}
                          onBlur={() => checkTaxes(values)}
                          style={{
                            fontSize: "16px",
                            height: "48px",
                            backgroundColor: "#fafafa",
                            width: "100%",
                          }}
                        />
                      )}
                    </FormFlexBoxItem>
                  </FormFlexBox>

                  <FormFlexBox marginTop={2}>
                    <FormFlexBoxItem>
                      <Typography
                        variant="body1"
                        style={{ marginBottom: "10px" }}
                      >
                        Country <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <SelectField
                        name={"country"}
                        id="country"
                        onFieldChange={(e: any) =>
                          (values.country = e.target.value)
                        }
                        helperText={
                          touched.country && errors.country
                            ? errors.country
                            : ""
                        }
                        error={touched.country && Boolean(errors.country)}
                        options={countryOptions}
                        variant={"filled"}
                        onBlur={() => checkTaxes(values)}
                        style={{
                          fontSize: "16px",
                          height: "48px",
                          backgroundColor: "#fafafa",
                          width: "100%",
                        }}
                      />
                    </FormFlexBoxItem>
                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        Postal code <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <Field
                        name="postalCode"
                        id="postalCode"
                        as={MUIField}
                        fullWidth
                        margin="normal"
                        helperText={
                          touched.postalCode && errors.postalCode
                            ? errors.postalCode
                            : ""
                        }
                        error={touched.postalCode && Boolean(errors.postalCode)}
                        variant="filled"
                        placeholder="60611"
                        onBlur={() => checkTaxes(values)}
                        InputProps={InputProps}
                      />
                    </FormFlexBoxItem>
                  </FormFlexBox>

                  <FormFlexBox marginTop={2}>
                    <FormFlexBoxItem>
                      <Typography variant="body1">
                        Phone number <span style={{ color: "#3057E1" }}>*</span>
                      </Typography>
                      <Field
                        name="phoneNumber"
                        id="phoneNumber"
                        as={MUIField}
                        fullWidth
                        margin="normal"
                        helperText={
                          touched.phoneNumber && errors.phoneNumber
                            ? errors.phoneNumber
                            : ""
                        }
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        variant="filled"
                        placeholder="1254567890"
                        InputProps={InputProps}
                      />
                    </FormFlexBoxItem>

                    <FormFlexBoxItem>
                      <Typography variant="body1">Extension</Typography>
                      <Field
                        name="phoneExtension"
                        id="phoneExtension"
                        as={MUIField}
                        fullWidth
                        margin="normal"
                        helperText={
                          touched.phoneExtension && errors.phoneExtension
                            ? errors.phoneExtension
                            : ""
                        }
                        error={
                          touched.phoneExtension &&
                          Boolean(errors.phoneExtension)
                        }
                        variant="filled"
                        placeholder="1"
                        InputProps={InputProps}
                      />
                    </FormFlexBoxItem>
                  </FormFlexBox>
                </BorderedBox>
                {(updateBillingForm && subscriptions && subscriptions.length) ||
                (billingInfoStoreState &&
                  billingInfoStoreState?.payment_methods &&
                  billingInfoStoreState.payment_methods.length) ||
                widget ? null : (
                  <BlueBox>
                    <Typography variant="body1">
                      <input
                        type="checkbox"
                        required
                        style={{ marginRight: 10 }}
                      ></input>
                      I understand that any subscription(s) will automatically
                      renew at the start of each billing cycle, and I may cancel
                      at any time.
                    </Typography>

                    <br />

                    <Typography variant="body1">
                      By clicking Pay now, you agree to Black Spectacles{" "}
                      <BlueLabel onClick={handleClick}>
                        Terms and Conditions
                      </BlueLabel>
                      {" "}, and you acknowledge the{" "}
                      <BlueLabel onClick={handleClickPolicy}>
                        Privacy Policy
                      </BlueLabel>
                      .
                    </Typography>
                  </BlueBox>
                )}
              </FormBox>
              <ButtonBox>
                <Button
                  className="formSubmitButton"
                  color={"primary"}
                  // Display Save for users that do have a subscription
                  children={
                    (updateBillingForm &&
                      subscriptions &&
                      subscriptions.length) ||
                    (billingInfoStoreState &&
                      billingInfoStoreState?.payment_methods &&
                      billingInfoStoreState.payment_methods.length) ||
                    widget
                      ? "Save"
                      : "Pay now"
                  }
                  size={"large"}
                  type={"submit"}
                  disabled={isSubmitting || !isStripeValid}
                />

                {updateBillingForm && updateCardFormVisible ? (
                  <Button
                    className="formCancelButton"
                    color={"whiteGreyBorder"}
                    children={"Cancel"}
                    size={"large"}
                    onClick={() => {
                      handleCancel()
                    }}
                  />
                ) : null}
              </ButtonBox>
            </Form>
          )}
        </Formik>
      </CheckoutBox>
      {open && isAREProduct ? (
        <CeoNoteDialog
          onSubmit={() => {
            window.location.href = process.env.REACT_APP_ARE_URL ?? "https://learn.blackspectacles.com"
          }}
          isOpen={open}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default View
